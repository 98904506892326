











import { Component, Mixins } from 'vue-property-decorator';
import { Office } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';
import Offices from '@/components/Offices.vue';

@Component({
  components: {
    Offices,
  },
})
export default class OfficeLocations extends Mixins(View) {
  private offices: Office[] = [];

  private readonly inRealEstate!: string;

  mounted() {
    API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
      this.offices = offices.filter((o) => o.Id !== 'b6b5cc8c-42db-4fea-884a-f494ae7a98d3');
    });
  }
}
